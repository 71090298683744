.default-color-transition{
  transition: 200ms ease-in;
  &:hover{
    transition: 200ms ease-in-out;
    color: $theme-third-color;
  }
}

.black-to-white-bg-transition{
  transition: 400ms;
  background-color: #000;
  color: #fff;
  &:hover{
    transition: 400ms;
    // border: 1px solid #000;
    box-shadow: 0px 0px 1px 0px #000;
    background-color: #fff;
    color: #000;
  }
}

.white-to-black-bg-transition{
  transition: 400ms;
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  &:hover{
    transition: 400ms;
    background-color: #000;
    color: #fff;
  }
}