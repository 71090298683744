.page-wrapper--authentication{
  border-radius: 0;
  border: none;
  .page-header{
    border: none;
    border-radius: 0;
    background-color: transparent;
    text-align: center;
  }
  .page-footer{
    // width: 75%;
    // max-width: 450px;
    // margin: 0 auto;
    background-color: transparent;
    .no-account{
      a{
        font-size: 19px;
        transition: 400ms ease;
        color: $theme-secondary-color;
        &:hover{
          transition: 400ms ease;
          color: $theme-third-color;
        }
      }
    }
  }
  .register-form{
    & > p{
      font-size: 29px;
      a {
        color: $theme-secondary-color;
        @extend .default-color-transition;
      }
    }
  }
  @media (min-width: 992px){
    .register-form{
      & > p{
        width: 75%;
        max-width: 450px;
        margin: 0 auto;
        
        a{
          
        }
      }
    }
    .page-wrapper--authentication{
      .page-footer{
        width: 75%;
        max-width: 450px;
        margin: 0 auto;
      }
    }
  }
}

