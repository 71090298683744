.breadcrumb{

  .breadcrumb-item{
    span{
      transition: 0.3s ease-in-out;
      color: #000;
    }
    &.active{
      cursor: pointer;
      span{
        transition: 0.3s ease-in-out;
        color: $theme-secondary-color;
      }
    }
    &:hover{
      span{
        transition: 0.3s ease-in-out;
        color: $theme-third-color;
      }
      &.active{
        span{
          transition: 0.3s ease-in-out;
          color: $theme-third-color;
        }
      }
    }
  }
}

@media (max-width:991px){
  .breadcrumb{
    display: none;
  }
}