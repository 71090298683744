#my-account{
  .account-links{
    .link-item{
      border: none;
      box-shadow: 0 0 3px 0 #000;
      border-radius: 0;
      transition: 200ms ease;
      align-items: center;
      justify-content: center;
      &:hover{
        transition: 400ms ease;
        box-shadow: 0 0 6px 0 #000;
      }
      i{

      }
    }
  }
  .page-footer--my-account{
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 25px;
    a{
      color: $theme-secondary-color;
      box-shadow: 0 0 3px 0 #000;
      padding: 0.375rem 0.725rem;
      @extend .default-color-transition;
    }
  }
  @media (max-width:768px){
    .account-links > a{
      padding-right: calc(var(--bs-gutter-x) * .5);
      padding-left: calc(var(--bs-gutter-x) * .5);
    }
  }
  @media (max-width:1199px){
    .account-links > a{
      padding-right: calc(var(--bs-gutter-x) * 1);
      padding-left: calc(var(--bs-gutter-x) * 1);
    }
  }
}

.order-actions{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  a{
    font-size: 17px;
    max-width: 220px;
  }
}

@media (max-width:768px){
  .orders.visible--mobile{
    .order{
      box-shadow: 0px 0px 3px 0px black;
      padding: 1rem .5rem;
      .row{
        flex-wrap: nowrap;
        a{
          font-size: 24px;
        }
        .date, .total{
          font-size: 19px;
        }
        .status{
          margin-top: 1rem;
          span{
            padding: 0.5rem .7rem;
            font-size: 15px;
          }
        }
        .col-10{
          // padding-right: 0rem;
        }
        .col-2{
          padding: 0;
          margin-left: -15px;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          a{
            font-size: 1rem;

          }
          a[data-link-action=view-order-details]{
              border-bottom: none;
          }
        }
      }
    }
  }
}
@media (max-width:576px){
  .orders.visible--mobile{
    .order{
      .status{
        margin-top: 2rem;
        margin-left: -2rem;
        // margin: auto;
      }
    }}
}
.page-wrapper{
  border: none;
  .page-header{
    text-align: center;
    border: none;
    background-color: #fff;
  }
  .page-footer{
    border: none;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 15px;
    a{
      font-size: 19px;
      max-width: 220px;
      width: 100%;
      padding: .325rem .725rem;
      padding-top: .6rem;
      box-shadow: 0 0 3px 0 #000;
      color: $theme-secondary-color;
      @extend .default-color-transition;
    }
  }
}
@media (max-width:576px){
  .page-wrapper{
    .page-header{
      
    }
    .page-footer{
      margin: 0 -20px;
      flex-wrap: wrap;
    }
  }
}

.page-wrapper{
  .btn-outline-primary{
    border-radius: 0;
    border-color: #000;
    color: #000;
    &:hover{
      color: #fff;
      background-color: #000;
    }
  }
}