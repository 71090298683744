#contact{
  .row{
    // justify-content: center;
    #left-column{
      margin-top: 2rem !important;
      h4{
        font-size: 32px;
      }
      .block{
        display: flex;
        align-items: center;
        gap: 10px;
        .data{
          font-size: 21px;
        }
        .icon{
          font-size: 34px;
        }
      }
    }
    #content-wrapper{
      max-width: 750px;
      margin-left: 1rem;
      .contact-form{
        .form-group{
          &:before{
            top: 58%;
            left: 59%;
          }
          .custom-file{
            position: relative;
            z-index: 11;
            cursor: pointer;
          #fileUpload{
            padding-left: 18px;
            padding-top: 3px;
            font-size: 21px;
            width: 300px;
            &::file-selector-button{
              opacity: 0;
            }
          }
        &:hover:active{
          &:before{
            
            background-color: #707070;
            border-color: #707070;
          }
        }
        &:hover{
          &:before{
            transition: 0.3s ease;
            background-color: #000;
            color: #fff;
          }
        }
        &:before{
          transition: 0.3s ease-in-out;
          position: absolute;
          width: 100px;
          height: 40px;
          border: 1px solid black;
          border-radius: 0;
          content: "Wybierz Plik";
          text-align: center;
          padding-top: 0.3rem;
          font-size: 19px;
        }
        .custom-file{
          &-input{
            // opacity: 0; 
            height: 40px;
            width: 190px;
            // width: 100px;          
            // cursor: pointer;
          }
          &-label{
            display: none;
            &.selected{
              display: block;
            }
            padding-top: 6px;
          }
        }
      }
        }
      }

      .btn-primary{
        background-color: #fff;
        border-color: #000;
        padding-top: 0.5rem;
        font-size: 19px;
        color: #000;
        border-radius: 0;
        width: 100px;
        &:hover{
          transition: 0.3s ease-in-out;
          background-color: #000;
          color: #fff;
        }

      }
    }
  }
}

@media (max-width:1199px){
  #contact{
    .row{
      justify-content: center;
      align-items: center; 
      flex-direction: column-reverse;
      #left-column{
        width: 70%;
      }
    }
  }
}
@media (max-width:991px){
  #contact{
    .row{
      flex-direction: column-reverse;
      #content-wrapper .contact-form .form-group:before{
        left: 43%;
      }
    }
  }
}
@media (max-width:768px){
  #contact{
    .row{
      flex-direction: column-reverse;
      #content-wrapper .contact-form .form-group:before{
        left: 260px;
      }
    }
  }
}

#cms{
  #content{
    max-width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }
}