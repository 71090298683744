#header-search{

  .search-widget__group{
    .form-control, .btn{
      border: 1px solid #000;
      color: #000;
      i{
        color: #000;
      }
      &:focus{
        box-shadow: none;
      }
    }
    .btn{
      background-color: #fff;
      
      &:hover{
        transition: 300ms;
        background-color: #000;
        i{
          color: #fff;
        }
      }
    }
  }
}

.autocomplete-suggestions{
  max-height: 350px !important;
  position: fixed !important;
  top: 130px !important;
  &::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #404040;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 76px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #737272;
    border: 1px solid #000;
  }

  .autocomplete-suggestion{
    display: flex;
    align-items: center;
    padding: .5rem;
    border-bottom: 1px solid rgba(160, 160, 160, 0.019);
    transition: 200ms ease;
    &:hover{
      transition: 200ms ease;
      background-color: rgba(160, 160, 160, 0.151);
    }
    img{
      max-width: 100px;
      max-height: 100px;
    }
    .media-body{
      font-size: 29px;
      /* margin-top: 50%; */
      padding-left: 0.5rem;
    }
  }
}

.visible--mobile.text-right.up{
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#search{
  #js-product-list-top{
   .total-products{
    display: none;
   } 
   .sort-by-row{
    .form-group{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .select-button{
        height: 52px;
        .selected-value.selected {
          position: absolute;
          background: white;
          z-index: 2;
          width: 69%;
        }
      }
      label{
        height: 30px;
        font-size: 21px;
      }
    }
   }
  }
}