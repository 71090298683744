#cart{
  .featured-products{
    margin-top: 2rem;
  }
  .cart-grid{
    position: relative;
    .cart-grid-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      .card{
        border: none;
        .card-header{
          // border: none;
          background-color: #fff;
        }
        .card-body{
          .cart-item{
            border: none;
            padding-bottom: 0;
          }
          .product-line-grid{
            padding: 1rem;
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.274);
            .media{
              display: flex;
              align-items: center;
              .media-body{
                display: flex;
                flex-direction: column;
                align-items: center;
                .product-line__title{
                  font-size: 23px;
                  text-align: center;
                }
                .product-line-info{
                  flex-direction: column; 
                  div{
                    font-size: 19px;
                  }
                }
              }
            }
          }
          .col-lg-5{
            padding-right: 0;
          }
          .product-line-grid-right{
            justify-content: center;
            
            height: 100%;
            .product-quant-price{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 15px;
              .input-group{
                box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
                .input-group{
                  &-prepend{
                    button{
                      border-right: 2px solid rgba(0, 0, 0, 0.082);
                      // box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
                      border-radius: 0;
                      &:hover{
                        background-color: rgba(0, 0, 0, 0.040);
                        transition: all 200ms ease;
                      }
                      &:active{
                        border: none;
                        border-color: rgba(0, 0, 0, 0.082);
                        border-right: 2px solid rgba(0, 0, 0, 0.082);
                      }
                    }
                  }
                  &-append{
                    button{
                      border-left: 2px solid rgba(0, 0, 0, 0.082);
                      border-radius: 0;
                      &:hover{
                        background-color: rgba(0, 0, 0, 0.040);
                        transition: all 200ms ease;
                      }
                      &:active{
                        border: none;
                        border-color: rgba(0, 0, 0, 0.082);
                        border-left: 2px solid rgba(0, 0, 0, 0.082);
                      }
                      &:focus-visible{
                        border: none;
                      }
                    }
                    &:focus-visible{
                      border: none;
                      outline: none;
                    }
                  }
                  button{
                    transition: 300ms ease;
                    &:hover{
                      transition: 300ms ease;
                      background-color: rgba(0, 0, 0, 0.192);
                    }
                  }
                }
              }
            }
            .cart-line-product-actions{
              margin-left: 2rem;
            }
          }
        }
        .no-items{
          font-size: 24px;
        }
      }
      .label{
        @extend .btn-main-reversed;
        margin-top: auto;
        font-size: 24px;
        display: flex;
        align-items: center;
        width: 180px;
        text-align: center;
        padding-top: 0.375rem;
        color: #000;
      }
    }
    .cart-grid-right{
      display: flex;
      flex-direction: column;
      position: relative;
      
      .card{
        border: none;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.308);
        position: sticky;
        width: 100%;
        
      }
    }
  }
  .remove-from-cart{
    .trash-icon{
      height: 30px;
      width: 24px;
    }
    color: $theme-secondary-color;
    transition: 200ms;
    &:hover{
      color: $theme-third-color;
      transition: 200ms;
    }
  }
}
.cart-sticky{
  position: sticky;
  position: -webkit-sticky;
  top: 10%;
  .accordion-item{
    background-color: #fff;
    border-radius: 0;
    .accordion-header{
      .accordion-button{
        background-color: #fff;
        &:focus-visible{
          box-shadow: none;
          border: none;
        }
        &:focus{
          box-shadow: none;
        }
      }
    }
  }
  .card-body{
    border: none;
    font-size: 19px;
    .cart-summary-line{
      font-size: 19px;
    }
    .cart-detailed-totals{

      .cart-summary-line{
        background-color: #fff;
      }
    }
  }
  .card-footer{
    border: none;
    background-color: #fff;
    .btn-primary{
      @extend .btn-main-reversed;
      border-color: #636363;
      background-color: #fff;
      color: #000;
      &.disabled{
        opacity: 1;
        background-color: #636363;
        color: #fff;
      }
    }
  }
  .separator{
    border: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px){
  #cart{
    .cart-grid{
      .cart-grid-body{
        padding-left: 0;
        .card{
          .cart-items{
            .product-line-grid{
              .media{
                flex-direction: column;
                .media-body{
                  text-align: center;
                }
              }
              .col-lg-5{
                padding-right: calc(var(--bs-gutter-x) * 1);
                padding-left: calc(var(--bs-gutter-x) * 1);
              }
              .product-line-grid-right{
                .cart-line-product-actions{
                  margin-left: 2rem;
                }
              }
            }
          }
        }
      }
      .cart-grid-right{
        padding-left: calc(var(--bs-gutter-x) *.5);
        padding-right: calc(var(--bs-gutter-x) * .5);
      }
    }
  }
}

@media (max-width:991px){
  #cart{
    .cart-grid{
      .cart-grid-body{
        .card{
          .card-body{
            .cart-items{
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              .cart-item{
                margin-bottom: $spacer;
                flex: 0 0 45%;
                max-width: 45%;
                min-width: 300px;
                .product-line-grid{
                  flex-direction: column;
                  margin-right: 0;
                  margin-left: 0;
                  height: 100%;
                  .col-12{
                    // flex: 0 0 50%;
                    // width: 50%;
                    padding-left: calc(var(--bs-gutter-x) * .5);
                    padding-right: calc(var(--bs-gutter-x) * .5);
                    .media{
                      flex-direction: column;
                      .product-line__title{
                        margin-top: .5rem;
                      }
                    }
                  }
                  .col-lg-5{
                    padding-left: 0;
                    margin-top: auto;
                  }
                  .product-line-grid-right{
                    flex-direction: column;
                    .cart-line-product-actions{
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:767px){
  #cart{
    .product-line__img{
      width: 200px;
      margin-right: 0;
    }
    .cart-grid .cart-grid-body .card .card-body .cart-items .cart-item{
      flex: 0 0 75%;
      max-width: 75%;
      max-width: 350px;
    }
  }
}

@media (max-width: 991px){
  #cart{
    .remove-from-cart{
    }
    .product-quant-price{
      .product-price{
        font-size: 21px;
      }
    }
  }
}