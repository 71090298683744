.modal.quickview{
  z-index: 998;
  // .modal-content{
  //   z-index: 998;
  // }
}
.modal-backdrop{
  z-index: 997;
}

.simple-lightbox{
  z-index: 999;
}
.sl-overlay{
  z-index: 998;
}