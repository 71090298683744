.featured-products{
  .products-section-header{
    .products-section-title{
      font-size: 29px;
      letter-spacing: 1px;
      font-weight: 500;
    }
    .swiper-button-prev, .swiper-button-next{
      width: 52px;
      height: 54px;
      background-color: transparent;
      border: 2px solid #000;
      transition: 100ms;
      svg{
        color: #000;
      }
      &::after{
        display: none;
      }
      &:hover{
        // border-color: #fff;
        transition: 100ms;
        // background-color: #0000000c;
        border: 3px solid #000;
        svg{
          transition: 100ms;
          
        }
      }
    }
  }
  .products{
    .swiper-wrapper{
      padding-left: 0;
      padding-right: 0;
    }
  }
}