#footer{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin: 1rem auto;
  border-top: 1px solid $main-border-color;
  .cms-page-link,a{
    transition: 200ms;
    &:hover{
      transition: 200ms;
      color: $theme-third-color;
    }
  }
  .copywrite-wrapper{
    order: 3;
    flex: 0 0 100%;
    width: 100%;
    .copyright-text{
      padding-top: 2rem;
      a{
        color: $theme-third-color;
      }
    }
  }
  @media (min-width: 576px){
    max-width: 540px;
  }
  @media (min-width: 768px){
    max-width: 720px;
  }
  @media (min-width: 992px){
    max-width: 960px;
  }
  @media (min-width: 1200px){
    max-width: 1140px;
  }
  @media (min-width: 1400px){
    max-width: 1320px;
  }
  @media (min-width: 1760px){
    max-width: 1560px;
  }
  .block_newsletter{
    #block-newsletter-title{
      color: #000;
      font-weight: 500;
      text-align: center;
    }
    #block-newsletter-label{
      text-align: center;
    }
    .newsletter-content{
      .input-group{
        .form-control{
          border: 1px solid #000;
          background-color: transparent;
          border-radius: 0;
        }
        .btn-primary{
          transition: 200ms ease;
          background-color: transparent;
          color: #000;
          border: 1px solid #000;
          border-left: none;
          border-radius: 0;
          &:hover{
            transition: 200ms ease;
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
  .newsletter-container{
    flex: 0 0 30%;
    width: 30%;

  }
  .footer-container{
    flex: 0 0 70%;
    width: 70%;
    .row-footer{
      display: flex;
    }
  }
}
.footer-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  img{
    width: 100%;
    @media (max-width:991px){
      // width: 50%;
      display: none;
    }
  }
}
.l-footer {
  color: $text-muted;


  .cms-page-link,
  .account-list a{
    color: $text-muted;
    &:hover{
      color: theme-color('primary');

    }
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
}

.footer__title{
  color:$black;
  &:visited,&:hover{
    color: $black;
  }
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: calc($spacer / 2);
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: calc($spacer / 2) 0;
    // border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: 6.75rem;
      transition: .3s transform ease-in-out;
      font-size: calc($font-size-base * 1.5);
    }
    &[data-bs-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

#footer_sub_menu_social{
  li{
    display: flex;
    align-items: center;
  }
  @media (max-width:991px){
    li{
      justify-content: center;
    }
  }
  .footer-facebook, .footer-instagram {
    &:before{
      font-family: "Bootstrap-icons";
      font-size: 27px;
      color: #000;
    }
    margin-right: .5rem;
  }
  .footer-facebook{
    &:before{
      content: '\F344';
    }
  }
  .footer-instagram{
    &:before{
      content: '\F437';
    }
  }
  .footer-twitter{
    svg{
      color: #000;
      width: 26px;
      height: 26px;
      margin-bottom: 0.1rem;
    }
  }
}

@media (max-width:991px){
  .cart-sticky{
    position: static;
  }
  .row-footer{
    width: 560px;
    position: relative;
    margin: auto;
    .footer__title--mobile:after{
      right: 0;
    } 
  }
  .footer__title{
    &--mobile{
      display: block;
    }
    &--desktop{
      display: none;
    }
  }
  #footer{
    margin: 1rem 1rem;
    .newsletter-container{
      .block_newsletter .row-newsletter{
        .newsletter-desc{
          text-align: left;
        }
      }
    }
    .footer-container{
      .row-footer{
        flex-direction: column;
        .col-lg-3{

        }
      }
    }
    .text-center{
      margin-top: 2rem;
    }
  }
}

@include media-breakpoint-mobile {
  .row-footer{
    width: 360px;
    .footer__title--mobile:after{
      right: 0;
    }
  }
  // .footer__title{
  //   &--desktop{

  //     display: none;
  //   }
  //   &--mobile{
  //     display: block;
  //   }
  // }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}

@media (max-width:1199px){
  #footer{
    margin: 1rem 5rem;
  }
}

@media (max-width:991px){
  #footer{
    flex-direction: column;
    .newsletter-container{
      padding: 2rem 5%;
      flex: 0 0 90%;
      width: 90%;
      margin: auto;
      .block_newsletter .row-newsletter{
        display: flex;
        flex-direction: column;
        margin: auto;
        .newsletter-desc{
          margin: auto;
        }
        .newsletter-content{
          .form-control{
            border-radius: 0;
          }
        }
      }
    }
    .footer-container{
      flex: 0 0 100%;
      width: 100%;
    }
  }
}

@include media-breakpoint-mobile() {
  #footer{
    // margin: 1rem 1rem;
    margin: auto;
    .newsletter-container{
      .block_newsletter .row-newsletter{
        .newsletter-desc{
          text-align: left;
        }
      }
    }
    .footer-container{
      .row-footer{
        flex-direction: column;
        .col-lg-3{

        }
      }
    }
    .text-center{
      margin-top: 2rem;
    }
  }
}

@media (max-width:575px){
  .row-footer{
    width: 360px;
    .footer__title--mobile:after {
      right: 0;
    }
  }
  #footer{
    .newsletter-container{
      .block_newsletter .row-newsletter{
        flex-direction: column;
        .newsletter-desc, .newsletter-content{
          flex: 0 0 100%;
          max-width: 100%;
          margin: auto;
        }
      }
    }
  }
}