// unique styles
.row > *, .products > * {
  padding-right: calc(var(--bs-gutter-x) * 2);
  padding-left: calc(var(--bs-gutter-x) * 2);
}
.product-miniature{
  .card{
    border-radius: 0;
    border: none;
    .card-img-top{
      border-radius: 0;
    }

    .card-body{
      padding: .725rem 0;
      z-index: 1;
      position: relative;
      background: #fff;
      .product-title{
        text-align: center;
        font-size: 21px;
        line-height: 21px;
        font-weight: 400;
        margin-left: .4rem;
        margin-bottom: 1rem;
        a{
          color: #000;
        }
      }
      .product-price-and-shipping{
        display: flex;
        flex-direction: column;
        align-items: center;
        span{
          font-size: 25px;
          line-height: 28px;
          font-weight: 500;
        }
        .sr-only{
          display: none;
        }
        .regular-price{
          font-size: 21px;
          color: #000;
        }
        .current-price-discount{
          
          color: rgb(218, 53, 53);
        }
        margin-bottom: 1rem;
      }
      .product-add-to-cart{
        text-align: center;
        .btn-main{
          width: 80%;
        }
      }
    }
  }
}


// page styles