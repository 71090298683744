#header{
  &.header-box-shadow{
    box-shadow: 0px 0px 3px 0px black
  }
}

#header{
  // width: 100vw;
  .menu{
    padding-bottom: 0;
  }
  .header-nav{
    display: none;
  }
  .menu-top{
    padding-top: $spacer*1.25;
    padding-bottom: $spacer*1.25;
  }
  .header__search{
    transition: 200ms;
    padding-bottom: 1rem;
    button, .form-control{
      border-radius: 0;
    }
        &.hide{
        transition: 200ms;
        height: 0px;
        padding-bottom: 0rem;
        margin-top: 0;
      form {
        input,button{
          transition: 200ms;
          height: 0px;
          padding-bottom: 0px;
          padding-top: 0px;
          border: none;
          i{
            display: none;
          }
        }
      }
    }
    &.show{
      height: 4rem;
      transition: 200ms;
      form{
        input,button{
          transition: 200ms;
        }
      }
    }
  }
  
  #search-button-header, #wishlist-button-header{
    cursor: pointer;
  }

  .user-info, .search, .shopping-cart, .wishlist{
    a{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .header__right{
    & div:not(:last-child){
      // border-right: 1px solid lightgray;
      padding-right: .5rem;
    }
    div{
      &:hover{
        small{
          text-decoration: underline;
        }
      }
    }
  }
}
@media (max-width:991px){
  .header-top{
    // display: flex;
    // display: grid !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    justify-content: flex-start !important;
    #menu-icon{
      order: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
      i{
        font-size: 35px;
      }
      small{
        font-size: 16px;
      }
    }

    .header__logo{
      order: 1;
    }
    .header-menu{
      order: 3;
      display: none;
    }
    .header__right{
      order: 4;
      flex-grow: 1;
      // align-self: flex-end;
    }
  }

}

.l-header{
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: $header-bg;
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  // padding-top: $spacer*1.25;
  // padding-bottom: $spacer*1.25;
  
}
.header__right{
  i, .wishlist .material-icons{
    font-size: 35px;
  }
  small{
    font-size: 16px;   
  }
}
.header__search{
  flex: 1;
  padding-right: calc($grid-gutter-width/2);
  padding-left: calc($grid-gutter-width/2);
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $menu-topitem-padding;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
  .header__logo,
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    flex: auto;
    order: 10;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  
}
@media (min-width: 1199px){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}


//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}

@media (max-width: 991px){
  #header{
    .cart-products-count{
      display: none;
    }
    .header__search{
      form{
        width: 50% ;
      }
    }
  }
}