@import '../Animations/custom.scss';

.user-info{
  position: relative;
  cursor: pointer;
  .account-box{
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    align-items: center;
    position: absolute;
    left: -132.65px;
    z-index: 99;
    background-color: #fff;
    width: 300px;
    border: 1px solid black;
    padding: 1rem;
    a{
      max-width: 160px;
      width: 100%;
    }
    &.hidden-start{
      display: none;
    }
    &.hidden{
      display: flex;
      animation: slide-out 800ms forwards;
      &.animated{
        animation-fill-mode: forwards;
        display: none;
      }
    }
    &.show{
      display: flex;
      animation: slide-in 800ms forwards;
      
    }
    .break{
      width: 100%;
      // border: 1px dotted blue;
      position: relative;
      margin: 1rem 0;
      text-align: center;
      color: rgb(82, 82, 82);
      .custom-line{
        padding-right: 50px;
        position: absolute;
        border-top: .5px solid rgb(110, 110, 110);
        top: 10px;
        &:first-child{
          left: 0px;
        }
        &:not(:first-child){
          right: 0px;
        }
      }
    }
    
  }
}