.modal.quickview{
  .images-container{
    .product-thumbs{
      display: none;
    }
  }
  .position-relative{
    height: 100%;
  }
}

.modal.quickview{
  .col.col-sm-6.visible--desktop{
    overflow: hidden;
    max-height: 512px;
  }
}
.images-container{
  display: flex;
  gap: 5px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  .product-thumbs{
    width: 180px;
    height: 100%;
    .product-thumb{
      height: 100%;
      border: none;
      .rc{
        background: transparent;
        padding-top: 89%;
      }
    }
  }
  .position-relative{
    flex: 0 0 74%;
    max-width: 74%;
    height: 718px;
    width: auto;
    .slick-arrow{
      display: none;
    }
    .dots-container{
      position: absolute;
      bottom: -1%;
      left: 50%;
      transform: translateX(-50%);
      .slick-dots{
        display: flex;

        li{
          button{
            opacity: 0;
          }
          &:before{
            content: '';
            display: block;
            border: 1px solid transparent;
            border-radius: 50%;
            width: 1em;
            height: 1em;
            background-color: #949494bf;
            transition: 0.3s ease-in-out;
          }
          &:hover{
            &:before{
              transition: 0.3s ease-in-out;
              background-color: #3c3c3cbf;
            }
          }
        }
        li.slick-active{
          &:before{
            transition: 0.3s ease-in-out;
            background-color: hsla(0, 0%, 24%, 0.749);
          }
        }
      }
    }
  } 
}
#product .product-thumbs .slick-slide{
  // width: 185px !important;
}
.slick-slide.slick-current{
  .product-thumb{
    position: relative;
    &:after{
      content: '';
      border-top: 3px solid #525252a1;
      border-bottom: 3px solid #525252a1;
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  // margin: -2px -2px -2px -2px;
}
#product{
  .images-container{
    margin-left: -4rem;
    .product-thumbs{
      .slick-list{
        overflow-y: hidden;
        z-index: 2;
      }
    }
    .position-relative{
      max-width: 68%;
    }
  }
}

.featured-products{
  position: relative;
  .products-section-header{
    position: unset;
  }
}

@media (max-width:1399px){
  #product .images-container{
    margin-left: 0;
  }
}