#order-confirmation{
  .page-content{
    border: none;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    border-radius: 0;
    .table__title-head{
      font-size: 21px;
    }
    .order-confirmation-table{
      border: none;
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
      .order-line:not(:last-of-type){
        border-bottom: var(--bs-border-width) solid var(--bs-border-color-translucent);
      }
      .order-line{
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        .details{
          font-size: 21px;
        }
        .qty{
          font-size: 21px;
          .text-right{
            // text-align: right;
          }
        }
      }
      .table{
        width: 50%;
        // margin: auto;
        // text-align: center;
        font-size: 21px;
      }
    }
    #order-details{
      width: 50%;
      // margin: auto;
      padding-left: calc(var(--bs-gutter-x) * 2);
      .card-title{
        font-size: 29px;
      }
      li{
        font-size: 21px;
      }
    }
  }
  #content-hook_payment_return{
    border-radius: 0;
    border: none;
    .card-body{
      border-radius: 0;
      border: none;
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
      .ps_checkpayment{
        p{
          font-size: 21px;
        }
        ul{
          
          li{
            font-size: 19px;
            span{
              font-size: 19px;
              strong{

              }
            }
          }
        }
      }
    }
  }
}