#category{
  .selected-value.placeholder-value{
    display: none;
  }
}
form{
  .required::after{
    content: '*';
    color: red;

  }
  .form-control{
    border-radius: 0;
    border: 1px solid #000;
  }
  .input-group-btn{
    button{
      @extend .btn-main-reversed;
      font-size: 19px;
      letter-spacing: .5px;
      width: 80px;
        // line-height: 19px;
      &:hover{
        border-color: #000;
        
      }
    }
  }
  .forgot-password{
    a{
      font-size: 19px;
      transition: 400ms ease-out;
      color: $theme-secondary-color;
      &:hover{
        transition: 400ms ease;
        color: $theme-third-color;
      }
    }
  }
  .form-footer{
    text-align: center;
    button{
      @extend .btn-main-reversed;
      border-color: #000;
      background-color: #fff;
      color: #000;
      width: 170px;
      font-size: 21px;
      line-height: 21px;
    }
  }
  .custom-control-label{
    font-size: 25px;
    em{
      font-style: normal;
      font-size: 19px;
      color: $theme-secondary-color;
    }
  }
  .form-fields{
    .form-group{
      margin: .5rem 0;
      &:has(.custom-select){
        display: flex;
        flex-direction: column;
        .custom-select{
          padding: 0.325rem 0.725rem;
          border-color: #000;
          background: #fff;
          font-size: 19px;
          padding-top: 0.6rem;
          &:focus-visible{
           
          }
        }
      }
      
      .label, label{
        font-size: 25px;
      }
      .form-control{
        // margin-top: 1px;
        padding-top: 0.6rem;
        font-size: 19px;
      }
      .custom-control{
        display: flex;
        & .custom-control-input[required]{
          position: relative;
          &::after{
            position: absolute;
            left: 35px;
            top: -5px;
            font-size: 29px;
            content: '*';
            display: block;
            color: red;
          }
        }
        input{
          margin-right: 15px;
          width: 2em;
          flex: 0 0 auto;
        }
        label{
          // margin-top: 5px;
        }
      }
    }
  }
}

input[type="checkbox"]{
  border-radius: 0.15em;
  transform: translateY();
}

input[type="radio"]{
  border-radius: 1em;
  transform: translateY();
  &:before{
    border-radius: 1em;
  }
}

input{
  &[type="checkbox"], &[type="radio"]{
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    margin-right: .15rem;
    width: 2em;
    height: 2em;
    font: inherit;
    color: currentColor;
    border: 0.15em solid currentColor;
    display: inline-grid;
    place-content: center;
    &:before{
      content: "";
      width: 1.25em;
      height: 1.25em;
      transform: scale(0);
      transition: 250ms transform ease-in-out;
      box-shadow: inset 1.5em 1.5em $theme-secondary-color;
    }
    &:checked::before{
      box-shadow: inset 1.5em 1.5em black;
      transform: scale(1);
      
    }
    &:hover{
      cursor: pointer;
      &::before{
        box-shadow: inset 1.5em 1.5em $theme-secondary-color;
        transform: scale(1);
      }
    }
  }
}

.form-group{
  .custom-select{
    padding: .375rem .725rem;
    
  }
  .color{
    span{
      display: none;
    }
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  font-size: 1.15rem;
  padding: 0.325em 4em 0.325em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0;
  color: #000;
  cursor: pointer;
}
.form-group{
  position: relative;
  &:has(.custom-select.active){
    &::before{
      transform: rotate(180deg);
    }
  }
}
.form-group:has(.custom-select){
  min-width: 200px;
  .custom-select{
    line-height: 32px;
    position: relative;
    // z-index: 11;
    width: 300px;
    max-width: 100%;
    font-size: 1.15rem;
    color: #000;
    // margin-top: 3rem;
    &.active{
      .select-dropdown {
        opacity: 1;
        visibility: visible;
        // transform: scaleY(1);
        height: 200px;
      }
    }
    .select-button {
      width: 100%;
      font-size: 1.15rem;
      background-color: #fff;
      padding: 0.675em 1em;
      border: 1px solid #caced1;
      border-radius: 0.25rem;
      cursor: pointer;
  
      display: flex;
      justify-content: space-between;
      align-items: center;

      .selected-value {
        text-align: left;
      }
    }
    .select-dropdown {
      position: absolute;
      list-style: none;
      z-index: 11;
      width: 92.5%;
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border: 1px solid #caced1;
      border-radius: 4px;
      padding: 10px;
      margin-top: 10px;
      height: 0%;
      max-height: 200px;
      overflow-y: auto;
      transition: 0.5s ease;
    
      // transform: scaleY(0);
      opacity: 0;
      visibility: hidden;
      &:focus-within {
        box-shadow: 0 10px 25px rgba(94, 108, 233, 0.6);
      }
      li {
        position: relative;
        cursor: pointer;
        display: flex;
        gap: 1rem;
        align-items: center;

        option{
          padding: 5px;
          font-size: 19px;
          width: 100%;
          cursor: pointer;

        }
      }
      &:-webkit-scrollbar {
        width: 7px;
      }
      &:-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 25px;
      }
      &:-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 25px;
      }
      li:hover, input:checked ~ label {
        background-color: #f2f2f2;
      }
      input:checked ~ label {
        background-color: #f2f2f2;
      }
      input:focus ~ label {
        background-color: #dfdfdf;
      }
    }
  }
  &::before{
    position: absolute;
    z-index: 11;
    font-family: 'Bootstrap-icons';
    content: "\F22C";
    right: 1.5rem;
    pointer-events: none;
    transition: transform ease-in-out 0.3s;
    
  }
  &:before{
    top: 30%;
    // transform: translateY(-50%);
  }
}

.product-variants{
  .form-group{
    &::before{
      display: none;
    }
    .custom-select{
      width: 150px;
      .select-button{
        padding-right: 2em;
      }
      &:before{
        position: absolute;
        z-index: 11;
        font-family: 'Bootstrap-icons';
        content: "\F22C";
        right: 1.5rem;
        pointer-events: none;
        transition: transform ease-in-out 0.3s;
        top: 30%;
          }
    }
  }
}
/* interactivity */

@media (max-width:768px){
  input[type=checkbox]{
    // width: 1.5em;
    // height: 1.5em;
    &:before{
      margin-left: 10px;
      // width: 0.75em;
      // height: 0.75em;
    }
  }
}

@media (max-width:576px){
  #checkout #js-checkout-process{
    padding-left: 0;
    padding-right:0;
  }
}