@keyframes slide-in{
  from {
    // transform: translateX(100%);
    opacity: 0;
    // width: 0px;
  }
  to{
    opacity: 1;
    // width: 300px;
    // transform: translateX(0%);
  }
}
@keyframes slide-out{
  0% {
    opacity: 1;
    // width: 300px;
    // transform: translateX(0%);
  }
  100%{
    opacity: 0;
    // width: 0px;
    // transform: translateX(100%);
  }
}

// searchbar animation

@keyframes expand-horizontal{
  0%{
    height: 0px;
  }
  100%{
    height: 40px;
  }
}

@keyframes custom-collapse-horizontal{
  0%{
    height: 40px;
    
  }
  100%{
    height: 0px;
    padding-top: 0;
    padding-bottom: 0;
  }
}