@media (min-width: 1760px){
  .container-xxl,
   .container-xl,
    .container-lg,
     .container-md,
      .container-sm,
       .container,
        .l-wrapper--boxed,
         .l-wrapper,
          main > .notifications-container{
            max-width: 1560px;
  }
}

@media (max-width: 991px){
  .container-xxl,
   .container-xl,
    .container-lg,
     .container-md,
      .container-sm,
       .container,
        .l-wrapper--boxed,
         .l-wrapper,
          main > .notifications-container{
            max-width: 960px;
  }
}
@media (max-width: 768px){
  .container-xxl,
   .container-xl,
    .container-lg,
     .container-md,
      .container-sm,
       .container,
        .l-wrapper--boxed,
         .l-wrapper,
          main > .notifications-container{
            max-width: 720px;
  }
}
@media (max-width: 576px){
  .container-xxl,
   .container-xl,
    .container-lg,
     .container-md,
      .container-sm,
       .container,
        .l-wrapper--boxed,
         .l-wrapper,
          main > .notifications-container{
            max-width: 520px;
  }
}

#index{
  .l-wrapper{
    max-width: 100%;
    padding: 0;
    & > .row{
      margin-left: 0;
      margin-right: 0;
    }
  }
  #content-wrapper{
    padding: 0;
  }
  .featured-products, .custom-text-block-wrapper{
    
    margin: 0 auto;
    @media (min-width: 576px){
      max-width: 540px;
    }
    @media (min-width: 768px){
      max-width: 720px;
    }
    @media (min-width: 992px){
      max-width: 960px;
    }
    @media (min-width: 1200px){
      max-width: 1140px;
    }
    @media (min-width: 1400px){
      max-width: 1320px;
    }
    @media (min-width: 1760px){
      max-width: 1560px;
    }
  }
}
