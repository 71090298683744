.facet__block{
  .facet__header.collapsed{
    .collapse_icon{
      transition: 400ms;
      rotate: 0deg;
    }
  }
  .facet__header:not(.collapsed){
    .collapse_icon{
      transition: 400ms;
      rotate: 180deg;
    }
  }
  .facet-slider-label{
    text-align: center;
    font-size: 25px;
  }
  .collapse{

  }
  .collapsing{
    
  }
}

#search_filters{
  .clear-all-wrapper{
    .btn-main-reversed{
      width: auto;
    }
  }
  .facet__block{
    .ui-slider-range{
      background: #000;
    }
    .ui-slider .ui-slider-handle{
      border-color: #000;
      border-radius: 0;
      width: .35em;
    }
  }
}

#products{
  .active_filters{
    background-color: #fff;
    box-shadow: 0px 0px 2px 0px #000;
    .active-filter__title{
      font-size: 21px;
    }
    .active_filters__list-item{
      @extend .btn-main-reversed;
      width: auto;

      // display: flex;
      font-size: 17px;
      letter-spacing: .5px;
      i{
        line-height: 23px;
    font-size: 18px;
      }
      span{
        margin-left: 3px;
      }
    }
  }
}

// Filter modal
#offcanvas_search_filter{
  .modal-header{
    padding: 0;
    .close{
      border: none;
      background-color: #fff;
      margin-left: auto;
      font-size: 50px;
      line-height: 30px;
      padding: 0.425rem 0.725rem 0rem 0.725rem;
    }
  }
  .modal-body{
    .search_filters{
      .clear-all-wrapper{
        text-align: right;
        .btn-main-reversed{
          width: auto;
          padding: .325rem .725rem;
        }
      }
      .facet__block{
        .facet__header{
          .facet__title{
            font-size: 27px;
          }
        }
        .custom-control{
          .custom-control-label{
            .color__label{
              font-size: 25px;
            }

          }
        }
        .faceted-slider{
          
        }
      }
    }
  }
  .modal-footer{
    .btn-main-reversed{
      width: 100%;
    }
  }
}

@media (max-width:991px){
  .sort-by-row{
    min-width: 400px;
    gap: 20px;
    .btn-main{
      font-size: 21px;
      line-height: 21px;
      padding: .4rem 1.2rem;
      width: auto;
    }
  }
}

@media (max-width:767px){
  .sort-container .sort-by-row{
    margin-top: 0.7rem;
    gap: 10px;
    justify-content: center;
  }
}
