#order-confirmation{

}

#content-hook_order_confirmation{
  background-color: rgba(41, 170, 47, 0.8) !important;
  border-left-width: 6px;
  i{
    margin-right: 0.5rem;
    font-size: 40px;
  }
  p{
    font-size: 21px;
  }
}

#content-hook_payment_return{
  p{
    font-size: 21px;
  }
  strong{
    font-size: 24px;
  }
  dl{
    dt{
      font-size: 21px;
    }
    dd{
      font-size: 20px;
    }
  }
}