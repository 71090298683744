.creahomebanners2-wrapper{
  position: relative;
  img{
    width: 100%;
    height: 100%;
  }
  .creahomebanners2--desc{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p, button{
      color: #fff;
      text-align: center;
    }
    p{
      font-size: 42px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 0;
    }
    .btn-creahomebanners2{
      border-color: #fff;
      font-size: 21px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 500;
      max-width: 170px;
      width: 100%;
    }
  }
}


.custom-text-block-wrapper{
  display: flex;
  .custom-text-block--image{
    flex: 0 0 50%;
    width: 50%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .custom-text-block-text-wrapper{
    flex: 0 0 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .custom-text-block--text{
      max-width: 480px;
      width: 100%;
      h1{
        font-size: 42px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 500;
      }
      button{
        font-size: 21px;
        padding-top: 0.4rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 500;
        max-width: 170px;
        width: 100%;
      }
      
    }
    button{
      padding: .125rem 2.5rem;
    }
  }
}

@media (max-width:768px){
  .custom-text-block-wrapper{
    flex-direction: column;
    .custom-text-block--image, .custom-text-block-text-wrapper{
      flex: 0 0 100%;
      width: 100%; 
    }
    .custom-text-block-text-wrapper{
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .creahomebanners2-wrapper .creahomebanners2--desc p{
    font-size:32px
  }
}

@media (max-width:576px){
  .creahomebanners2-wrapper .creahomebanners2--desc p{
    font-size: 25px;
  }
}