#checkout{
  #js-checkout-process{
    border-radius: 0;
    .checkout-process{
      & > section{
        border-radius: 0;
        margin-bottom: .3rem;
        &.-complete, &.-unreachable{
          border-bottom: none;
        }
        &.-current{
          border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
        }
        button[type="submit"]{
          &.btn-primary{
            @extend .btn-main-reversed;
            text-align: center;
            background-color: transparent;
            color: #000;
            min-width: 170px;
            margin-left: 50%;
            transform: translateX(-50%);
          }
        }
        input[type="radio"]{
          width: 1.5em;
          height: 1.5em;
          &:before{
            width: 1em;
            height: 1em;
          }
          &:checked:before{
            box-shadow: inset 1.5em 1.5em black;
            cursor:default;
          }
        }
      }
      .card-header{
        border-radius: 0;
        .step-title{
          margin-top: 4px;
          .step-edit{
            font-size: 17px;
            text-decoration: none;
            &:hover{
              text-decoration: underline;
            }
          }
        }
      }
      #content-checkout-personal-information-step{
        .card-body{
          display: flex;
          width: 75%;
          margin: auto;
          flex-direction: column;
          justify-content: center;
          p, div{
            text-align: center;
            font-size: 19px;
          }
          .btn-primary{
            @extend .btn-main-reversed;
            width: 120px;
            background-color: transparent;
            color: #000;
            padding-top: 0.6rem;
            margin-left: 0%;
            transform: translateX(0);
          }
          .tab-content{
            .form-group, p, em, .custom-control-label{
              text-align: left;
            }
            .custom-control{
              input::after{
                left: 40px;
              }
            }
          }
        }
      }
      #content-checkout-addresses-step{
        .card-body{
          .js-address-form{
            p{
              font-size: 19px;
              text-align: center;
              a{
                text-decoration: none;
                &:hover{
                  text-decoration: underline;
                }
              }
            }
            form{
              .form-fields{
                width: 60%;
                margin: auto;
              }
              .form-footer{
                button[type=submit].btn-primary {
                  margin-left: unset;
                  transform: translateX(0);
                }
                .cancel-address{
                  text-decoration: none;
                  @extend .btn-main;
                  color: #fff;
                  min-width: 170px;
                  font-size: 21px;
                  line-height: 21px;
                }
              }
            }
            #delivery-addresses, #invoice-addresses{
              justify-content: center;
              .card--address, .invoice-address{
                border-radius: 0;
              }
              .card-body_add-address{
                border-radius: 0;
                font-size: 19px;
                transition: 200ms ease;
                &:hover{
                  transition: 200ms ease;
                  color: $theme-third-color;
                }
              }
              .address-item{
                padding-right: calc(var(--bs-gutter-x) * 1);
                padding-left: calc(var(--bs-gutter-x) * 1);
                min-width: 290px;
                height: auto;
                label{
                  box-shadow: 0px 0px 3px 0px rgba(0,0,0,.3);
                  border-radius: 0;
                  border: none;
                  height: 100%;
                  transition: 300ms ease;
                  &:hover{
                    transition: 300ms ease;
                    box-shadow: 0px 0px 4px 0px rgba(0,0,0,.5);
                  }
                }
                &.selected{
                  label{
                    box-shadow: 0px 0px 4px 0px rgba(0,0,0,.5);
                    border: none;
                  }
                }
              }
              .card-body{
                font-size: 17px;
                
              }
              .card-footer{
                font-size: 17px;
                a{
                  transition: 200ms ease;
                  &:hover{
                    transition: 200ms ease;
                    color: $theme-third-color !important;
                  }
                  i{
                    font-size: 17px;
                    margin-right: 1px;
                  }
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
      #content-checkout-delivery-step{
        .delivery-options-list{
          .delivery-options{
            .delivery-option{
              display: flex;

              .custom-control{
                display: inline-block;
                flex: 0 0 30%;
                width: 30%;
              }
              .delivery-option-2{
                font-size: 24px;
                flex: 0 0 70%;
                width: 70%;
                display: flex;
                justify-content: space-between;
                .carrier-price{
                  color: $theme-third-color;
                }
              }
              
            }
          }
        }
      }
      #content-checkout-payment-step{
        .payment-option{
          .custom-control-label{
            font-size: 24px;
          }
        }
        .payment-options{
          .alert-info{
            border-radius: 0;
            border-color: transparent;
            background-color: transparent;
            color: #000;
            p{
              font-size: 22px;
            }
          }
          .ps_checkpayment--checkout{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(0,0,0,.3);
            background-color: transparent;
            width: 50%;
            margin: auto;
            padding: 2rem;
            
            p{
              font-size: 21px;
            }
            dt{
              font-size: 19px;
            }
            dd{
              font-size: 19px;
            }
          }
        }
        #conditions-to-approve{
          .custom-control{
            display: flex;
            justify-content: center;
            input{
              margin-right: 15px;
              @media (max-width:768px){
                margin-right: 5px;
                padding-right: 10px;
              }
            }
          }
        }
        #payment-confirmation{
          button[type="submit"].btn-primary{
            margin-left: unset;
            transform: translateX(0);
          }
        }
      }
    }
  }
  .cart-sticky{
    .link__showsummary{
      display: flex;
      align-items: center;
    }
    .cart-summary-line{
      font-size: 21px;
    }
    .media-list{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      
      .media:not(:last-child){
          border-bottom: 1px solid rgba(0,0,0,.1);
      }
      .media{
        flex: 0 0 100%;
        width: 100%;
        padding: 1rem;
        // box-shadow: 0px 0px 3px 0px rgba(0,0,0,.8);
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        // margin-left: .5rem;
        // margin-right: .5rem;
        a{
          margin-right: 2rem;
          img{
            width: 90px;
          }
        }
        .media-body{
          // text-align: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          .product-name{
            font-size: 21px;
          }
          .clearfix{
            display: flex;
            margin-top: auto;
            // align-items: baseline;
            .product-price-disc{
              margin-left: auto;
              display: flex;
              flex-direction: column;
              .regular-price{
                font-weight: 400;
                font-size: 18px;
                line-height: 18px;
                margin-left: auto;
              }
            }
          }
          .product-quantity{
            font-size: 18px;
            margin-top: auto;
            color: $theme-secondary-color;
          }
          .product-price{
            font-size: 20px;

          }
        }
      }
    }
  }
}
@media (max-width:1759px){
  #checkout{
    .order-checkout{
      padding-right: 0;
      padding-left: 0;
    }
  }
}