.home-slider-section {
  
  @include media-breakpoint-down('md') {
  }
}

.home-slider {
  min-height: 680px;
  width: 100%;
  .slider-item {
    position: relative;

    .slider-image {
      width: 100%;
      min-height: 680px;
      background-position: center;
      background-size: cover;
    }

    .slider-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 960px;
      width: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 70px;

      .slider-title {
        display: none;
        font-size: 90px;
        font-weight: 700;
        margin-bottom: 0;
      }

      .slider-cta {
        background-color: transparent;
        border-color: #fff;
        border-radius: 0;
        max-width: 170px;
        width: 100%;
        font-size: 21px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 500;
        &:hover{
          border-inline: 3px solid #fff;
        }
      }

      .slider-description {
        font-size: 42px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }

  .swiper-pagination.swiper-pagination-bullets {
    bottom: 20px;

    .swiper-pagination-bullet {
      background: #fff;
      width: 15px;
      height: 15px;

      &.swiper-pagination-bullet-active {
        background: #fff;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    background: none;
    border: none;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    color: #fff;

    svg {
      width: 100%;
      height: 100%;
    }

    &::after {
      content: none;
    }
  }

  .swiper-button-prev {
    left: 30px;
  }

  .swiper-button-next {
    right: 30px;
  }

  @include media-breakpoint-down('md') {
    .slider-item {
      .slider-content {
        .slider-title {
          font-size: 70px;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }

    .slider-item {
      .slider-content {
        padding: 0 15px;

        .slider-title {
          font-size: 50px;
        }

        .slider-description {
          font-size: 16px;
        }
      }
    }
  }
}