.wishlist-add-to{

  .modal-dialog{
    animation: slide-in 400ms;
    .modal{
      &-header{
        padding: 1rem 1.875rem;
        .modal-title{
          font-size: 24px;
        }
        .close{
          color: #000;
          display: flex;
          align-items: center;
          &:hover{
            opacity: 1;
            span{ 
              color: #000;
              transition: 200ms ease;
              transform: rotate(180deg);
            }
          }
        }
      }
      &-body{
        .wishlist-chooselist{
          .wishlist-list{
            li p{
              padding: 0.5rem 1.25rem;
              font-size: 19px;
            }
            li:not(:last-child){
              border-bottom: 1px solid rgba(0,0,0,0.1);
            }
            li:hover{
              background: rgba(0,0,0,0.05);
            }
          }
        }
      }
      &-footer{
        a{
          transition: 200ms ease;
          font-size: 19px;
          color: #000;
          i{
            transition: 200ms ease;
            color: #000;
          }
          &:hover{
            transition: 200ms ease;
            color: $theme-third-color;
            i{
              transition: 200ms ease;
              color: $theme-third-color;
            }
          }
        }
      }
    }
  }
}
.wishlist-button-add{
  i{
    color: $theme-third-color;
  }
}
footer .wishlist-toast{
  animation: slide-in;
  top: unset;
  bottom: 0.375rem;
  border-left-width: 7px;
  &.success{
    background-color: rgb(150, 233, 87);
    .wishlist-toast-text{
      color: #000;
    }
  }
  &.error{
    background-color: rgb(182, 77, 73);
  }
  .wishlist-toast-text{
    font-size: 19px;
  }
}

.wishlist{
  &-create, &-delete, &-share, &-rename{
    .modal-content{
      .modal{
        &-header{
          .close{
            color: #000;
            display: flex;
            align-items: center;
            &:hover{
              opacity: 1;
              span{ 
                color: #000;
                transition: 200ms ease;
                transform: rotate(180deg);
              }
            }
          }
        }
        &-body{
          padding: 0.9rem 3rem;
        }
        &-footer{
          button{
            padding-top: 0.6rem;
            font-size: 19px;
            min-width: 100px;
          }
          .btn-primary{
            @extend .btn-main;
            border-color: #000;
          }
          .btn-secondary{
            background-color: #fff;
            color: #000;
            @extend .btn-main-reversed;
          }
        }
      }
    }
  }
}

#module-blockwishlist-lists{
  .wishlist-modal:not(.show){
    transform: translateX(-300%);
  }
  .wishlist-modal.show{
    transform: translateY(-70px);
  }
  .page-wrapper{
    max-width: 524px;
    margin: auto;
    // .wishlist-footer-links{
    //   border: none;
    //   background-color: #fff;
    //   text-align: center;
    //   display: flex;
    //   justify-content: center;
    //   margin-top: 1rem;
    //   margin-bottom: 1rem;
    //   gap: 10px;
    //   a{
    //     max-width: 220px;
    //     width: 100%;
    //     font-size: 19px;
    //     text-align: center;
    //     padding: .5rem .725rem;
    //     box-shadow: 0 0 3px 0 #000;
    //     transition: 200ms ease;
    //     color: $theme-secondary-color !important;
    //     &:hover{
    //       transition: 200ms ease;
    //       color: $theme-third-color !important;
    //     }
    //   }
    //   }
  }
  .wishlist-container{

    &-header{
      .wishlist-add-to-new{
        transition: 200ms ease;
        font-size: 21px;
        color: #000!important;
        &:hover{
          transition: 200ms ease;
          color: $theme-third-color !important;

        }
      }
    }
    .page-content{
      border-radius: 0;
    }
    
    .wishlist-list{
      &-container{
      }
      &-item{
        transition: 200ms ease;
        &:hover{
          .wishlist-list-item-title{
            transition: 200ms ease;
            color: $theme-third-color;
          }
        }
      }
      &-item-link{
        .wishlist-list-item-title{
          font-size: 19px;
          span{
            font-size: 1.2rem;
            margin-left: 0.1rem;
            
          }
        }
        .wishlist-list-item-right{
          button{
            &:hover{
              opacity: 1;
            }
            i{
              transition: 200ms ease;
              color: #000;
              &:hover{
                transform: rotate(30deg);
                transition: 200ms ease;
                color: $theme-third-color;
              }
            }
          }
        }
      }
    }
  }
}

.wishlist-modal:not(.show){
  transform: translateX(-300%);
}

.page-wrapper{
  .wishlist-products-container{
    .wishlist-products-container-header{
      margin-bottom: 0;
      h1{

      }
      .sort-by-row{
        display: none;
      }
    }
    
    .page-content{
      border: none;
      .wishlist-products-list{
        justify-content: start;
        margin: 1.4375rem;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        .wishlist-products-item{
          box-shadow: 0px 0px 3px 0px lightgray;
          padding: 1rem;

          .wishlist-product-right{
            .wishlist-product{
              // justify-content: unset;
              // align-items: center;
              &-title{
                text-align: center;
                font-size: 21px;
                color: #000;
              }
              &-price{
                text-align: center;
                display: flex;
                flex-direction: column;
                font-size: 21px;
                gap: 3px;
                margin-top: 1rem;
                .wishlist-product-price-promo{
                  font-size: 19px;
                  color: rgb(180, 180, 180);
                }
              }
              &-combinations{
                // margin-top: auto;
                .wishlist-product-combinations-text{
                  display: flex;
                  flex-wrap: wrap;
                  font-size: 19px;
                  justify-content: space-between;
                  align-items: center;
                  width: 60%;
                  span:last-of-type{
                    font-size: 19px;
                    // margin-top: auto;
                  }

                  
                  span:not(:last-of-type){
                    opacity: 0;
                    height: 5px;
                  }
                }
                a{
                  // display: none;
                  i{
                    display: none;
                  }
                  &:hover{
                    &::before{
                      transition: 200ms ease;
                      color: $theme-third-color;
                    }
                  }
                  &:before{
                    display: block;
                    font-family: "Bootstrap-icons";
                    content: '\F341';
                    color: $theme-secondary-color;
                    transition: 200ms ease;
                    font-size: 28px;
                  }
                  
                }
              }
            }
          }
          .wishlist-product-bottom{
            .btn-primary, .btn-secondary{

              @extend .btn-main;
              border-color: #000;
              font-size: 19px;
              padding-top: 0.6rem;
              i{
                padding-top: 0.2rem;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
      
    }
  }
  
}

.page-wrapper{
  .wishlist-footer-links{
    border: none;
    background-color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 10px;
    & > a{
      max-width: 220px;
      width: 100%;
      font-size: 19px;
      text-align: center;
      padding: .5rem .725rem;
      box-shadow: 0 0 3px 0 #000;
      transition: 200ms ease;
      color: $theme-secondary-color !important;
      i{
        font-size: 1.5rem;
        margin-bottom: 2px;
      }
      &:hover{
        transition: 200ms ease;
        color: $theme-third-color !important;
      }
    }
    }
}

@media (max-width:991px){
  .page-wrapper .wishlist-products-container .page-content .wishlist-products-list{
    justify-content: center;
  }
}
@media (max-width:768px){
  .page-wrapper .wishlist-products-container .page-content.card{
    box-shadow: unset;
  }
  .page-wrapper .wishlist-products-container .page-content .wishlist-products-list{
    margin: .5rem;
    .wishlist-products-item{
      max-width: 412px;
      .wishlist-product{
        &-link{
          align-items: center;
          flex-direction: column;
        }
        &-image{
          width: 150px;
          height: 150px;
          img{
            // max-width: 200px;
            // max-height: 200px;
          }
        }
        &-bottom{
          .wishlist-button-add{
            position: absolute;
          }
        }
      }
    }
  }
}