.quickview{
  .modal-dialog{
    .modal-content{
      border-radius: 0;
    }
    .modal-header{
      border-radius: 0;
      .modal-title{

      }
      button{
        border: none;
        background-color: transparent;
        height: 60px;
        display: flex;
        align-items: center;
        transition: 200ms;
        span{
          font-size: 60px;
        }
        &:hover{
          span{
            font-size: 65px;
          }
        }
      }
    }
    .modal-body{
      .form-group{
        input[type="radio"]{
          width: 1.5em;
          height: 1.5em;
          &:before{
            width: 1em;
            height: 1em;
          }
        }
        .clearfix{
          display: flex;
          margin-bottom: 1rem;
        }
        .label-color{
          display: flex;
          align-items: center;
        }
      }
      .product-variants{
        padding-bottom: 1rem;
        .product-variants-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          label{
            font-size: 19px;
            input[type="radio"]{
              display: none;
            }
          }
          
        }
      }
      .product-prices{
        display: flex;
        justify-content: center;
        gap: 10px;
        .product-price.has-discount{
          display: flex;
          flex-direction: column;
          .product-discount{
            text-align: center;
            .regular-price{
              font-size: 19px;
            }
          }
          .current-price{
            .price{
              font-size: 21px;
            }
            .discount-percentage, .discount-amount{
              display: none;
            }
          }
        }
        .price{
          text-align: center;
          font-size: 21px;
        }
        .tax-shipping-delivery-label{
          
          display: flex;
          display: none;
          align-items: end;
          font-size: 21px;
        }
      }
      .product-actions{
        .product-quantity{
          justify-content: center;
          .quantity__label{
            display: none;
            font-size: 19px;
          }
          .qty{
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
          .add{
            width: 100%;
            text-align: center;
            button{
              background-color: transparent;
              color: #000;
              border-radius: 0;
              border-color: #000;
              padding-top: 0.6rem;
              transition: 200ms ease;
              &:hover{
                background-color: #000;
                color: #fff;
                transition: 200ms ease;
              }
            }
          }
          .product-availability{
            text-align: center;
            width: 100%;
            display: block;
          }
        }
        .input-group{
          #quantity_wanted{
            border: none;
          }
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.082);
          .input-group{
            &-prepend{
              button{
                border: none;
                border-right: 2px solid rgba(0, 0, 0, 0.082);
                // box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
                border-radius: 0;
                &:hover{
                  background-color: rgba(0, 0, 0, 0.040);
                  color: #000;
                  transition: all 200ms ease;
                }
                &:active{
                  border: none;
                  border-color: rgba(0, 0, 0, 0.082);
                  border-right: 2px solid rgba(0, 0, 0, 0.082);
                }
              }
            }
            &-append{
              button{
                border: none;
                border-left: 2px solid rgba(0, 0, 0, 0.082);
                border-radius: 0;
                &:hover{
                  background-color: rgba(0, 0, 0, 0.040);
                  color: #000;
                  transition: all 200ms ease;
                }
                &:active{
                  border: none;
                  border-color: rgba(0, 0, 0, 0.082);
                  border-left: 2px solid rgba(0, 0, 0, 0.082);
                }
                &:focus-visible{
                  border: none;
                }
              }
              &:focus-visible{
                border: none;
                outline: none;
              }
            }
            button{
              transition: 300ms ease;
              &:hover{
                transition: 300ms ease;
                background-color: rgba(0, 0, 0, 0.192);
              }
            }
          }
        }
      }
    }
    .modal-footer{
      .social-sharing{
        span{
          font-size: 21px;
          padding-top: 0.4rem;
        }
      }
    }
  }
    
  
}
#mobile_top_menu_wrapper{
  .modal-dialog{
    .modal-content{
      .modal-header{
        justify-content: end;
        button{
          span{
            display: none;
          }
          &:before{
            font-family: 'Bootstrap-icons';
            content: '\F659';
            font-size: 19px;
          }
        }
      }
      .modal-body{
        #contact-link{
          .u-link-body{
            font-size: 19px;
            color: $theme-third-color;
          }
        }
      }
    }
  }
}
.blockcart-modal {
  .modal-dialog{
    &.modal-lg{
      --bs-modal-width: 900px;
    }
    .modal-content{
      .modal-header{
        h3{
          i{
            display: none;
          }
        }
      }
      .modal-body{
        .divide-right{
          padding-right: 0;
          padding-left: var(--bs-gutter-x);
          .media{
            display: flex;
            .product-image{
              max-width: 250px;
            }
            .media-body{
              padding-top: 1rem;
              .product-name{
                color: #000;
                font-size: 21px;
              }
              .product-price{
                font-size: 24px;
              }
              .quantity{
                font-size: 18px;
              }
              .attributes-span{
                font-size: 18px;
              }
            }
          }
        }
        .cart-general{
          padding-right: var(--bs-gutter-x);
          padding-left: var(--bs-gutter-x);
          .cart-content{
            font-size: 19px;
          }
        }
      }
      .modal-footer{
        button{
          background-color: #fff;
          border-color: #000;
          color: #000;
          transition: 200ms ease;
          border-radius: 0;
          &:hover{
            transition: 200ms ease;
            background-color: #000;
            color: #fff;
          }
        }
        a{
          background-color: #000;
          border-color: #000;
          color: #fff;
          transition: 200ms ease;
          border-radius: 0;
          &:hover{
            transition: 200ms ease;
            background-color: #4e4e4e;
          }
        }
        button, a{
          font-size: 19px;
        }
      }
    }
  }
}

.modal-dialog{
  
  .modal-content{
    border-radius: 0;
    .modal-header{
      .modal-title{
      }
      button{
        border: none;
        background-color: transparent;
        transition: 200ms;
        
        span i{
          transform: translate(0deg);
        }
        &:hover{
          span i{
            transition: 200ms;
            transform: rotate(180deg);
          }
        }
      }
    }
    .modal-body{

    }
    .modal-footer{

    }
  }
}
@media (max-width:991px){
  .quickview{
    .modal-dialog{
      .modal-body{
        .image-container{
          padding: 0;
        }
        .product-actions{
          margin-left: -2rem;
          margin-right: -2rem;
          .product-quantity{
            .qty{
              width: auto;
              padding: 0;
            }
            .add{
              padding: 0;
            }
          }
        }
      }
    }
  }
}
@media (max-width:576px){
  .quickview{
    .modal-dialog{
      .modal-body{
        .row{
          flex-direction: column;
        }
      }
    }
  }
}