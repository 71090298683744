.thumbnail-top{
  position: relative;
}

.product-img{
}

#product{
  .blockreassurance_product{
    margin-top: 0;
  }
  #content-wrapper{
    .left-wrapper, .right-wrapper{
      padding-left: 0;
      padding-right: 0;
    }
    .left-wrapper{
      padding-right: 2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      & > #content{
        position: sticky;
        top: 15%;
      }
    }
    .right-wrapper{
      padding-left: 2rem;
      .category{
        display: flex;
        align-items: center;
        gap: 20px;
        .category-name{
          font-size: 21px;
          text-transform: capitalize;
        }
        .discount{
          font-size: 21px;
          line-height: 18px;
          padding: 0 1rem;
          font-weight: 400;
          padding-top: 0.2rem;
          background-color: #000;
          color: #fff;
        }
        p{
          margin-bottom: 0;
        }
      }
      .product-name{
        font-size: 50px;
        text-transform: none;
      }
      .product-prices{
        display: flex;
        .product-discount{
          .regular-price{
            font-size: 50px;
            margin-right: 1rem;
          }
        }
        .current-price{
          .discount{
            display: none;
          }
          .price{
            color: #000;
            font-size: 50px;
          }
        }
        .tax-shipping-delivery-label{
          font-size: 50px;
          margin-left: .5rem;
          display: none;
        }
      }
      .product-information{
        .product-description{
          font-size: 19px;
          p span, p{
            font-family: var(--bs-body-font-family) !important;
            // font-size: 21px !important;
          }
        }
        .product-actions{
          .product-variants{
            .form-group{
              label, .label{
                font-size: 21px;
              }
              .custom-select{
                line-height: 16px;
                .select-button{
                  &:focus-visible{
                    outline: none!important;
                  }
                }
                &::before{
                  top: 35%;
                }
              }
              .clearfix{
                display: flex;
              }
              .label-color{
                display: flex;
                // justify-content: center;
                // flex-direction: row;
                align-items: center;
                input[type="radio"]{
                  display: none;
                  width: 1.25em;
                  height: 1.25em;
                  &:before{
                    width: 0.5em;
                    height: 0.5em;
                    // margin-left: 0.4px;
                    // margin-top: 0.2px;
                  }
                }
              }
            }
          }
          .product-add-to-cart{
            .input-group{
              width: auto;
              #quantity_wanted{
                border: none;
              }
              box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.082);
              .input-group{
                &-prepend{
                  button{
                    border: none;
                    border-right: 2px solid rgba(0, 0, 0, 0.082);
                    border-radius: 0;
                    &:hover{
                      background-color: rgba(0, 0, 0, 0.040);
                      color: #000;
                      transition: all 200ms ease;
                    }
                    &:active{
                      border: none;
                      border-color: rgba(0, 0, 0, 0.082);
                      border-right: 2px solid rgba(0, 0, 0, 0.082);
                    }
                  }
                }
                &-append{
                  button{
                    border: none;
                    border-left: 2px solid rgba(0, 0, 0, 0.082);
                    border-radius: 0;
                    &:hover{
                      background-color: rgba(0, 0, 0, 0.040);
                      color: #000;
                      transition: all 200ms ease;
                    }
                    &:active{
                      border: none;
                      border-color: rgba(0, 0, 0, 0.082);
                      border-left: 2px solid rgba(0, 0, 0, 0.082);
                    }
                    &:focus-visible{
                      border: none;
                    }
                  }
                  &:focus-visible{
                    border: none;
                    outline: none;
                  }
                }
                button{
                  transition: 300ms ease;
                  &:hover{
                    transition: 300ms ease;
                    background-color: rgba(0, 0, 0, 0.192);
                  }
                }
              }
            }
            .product-quantity{
              margin: 0;
              margin-top: 1rem;
              .qty{
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin: 0px;
                margin-top: 2rem;
                label{
                  margin-top: 0;
                  font-size: 21px;
                  margin-right: 1rem;
                  display: none;
                }
              }
              .add{
                width: 100%;
                text-align: center;
                padding: 0;
                margin-top: 2rem;
                button{
                  background-color: transparent;
                  color: #000;
                  border-radius: 0;
                  border-color: #000;
                  width: 100%;
                  padding-top: 0.6rem;
                  transition: 200ms ease;
                  &:hover{
                    background-color: #000;
                    color: #fff;
                    transition: 200ms ease;
                  }
                }
              }
            }
          }
          .product-additional-info{
            display: flex;
            justify-content: center;

            .social-sharing{
              span{
                display: none;
              }
              a{
                display: flex;
                place-content: center;
                align-items: center;
                background-image: none;
                font-size: 28px;
                transition: 0.3s ease-in-out;
                &:before{
                  transition: 0.3s ease-in-out;
                  font-family: "Bootstrap-icons";
                  color: gray;

                }
                &:hover{
                  transition: 0.3s ease-in-out;
                  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.185) !important;
                  &:before{
                    transition: 0.3s ease-in-out;
                    color: $theme-third-color;
                  }
                }
              }
              .facebook{
                // opacity: 0;
                
                &:before{
                  content: '\F344';
                }
              }
              .twitter{
                &:before{
                  content: '\F5EF';
                }
                &:hover{
                  &:before{
                    color: rgb(104, 194, 230);
                  }
                }
              }
              .pinterest{
                &:before{
                  content: '\F663';
                  opacity: 0.4;
                  transition: "opacity" 0.8s ease-in-out;
                  color: rgb(3, 3, 3);
                  // font-size: 72px;
                }
                &:hover{
                  &:before{
                    color: gray;
                    opacity: 1;
                    transition: "opacity" 0.8s ease-in-out;
                    background: -webkit-linear-gradient(15deg, #e9003a 24%, #cc0231 64%, #c00323 97%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
              #pinterestGradient {
                .stop1 { 
                  stop-color: #e9003a;
                 }
                .stop2 { stop-color: #cc0231; }
                .stop3 { 
                  stop-color: #c00323; 
                }
               }
            }
            .wishlist-button-add{
              background-size: 80%;
              width: 2.5rem;
              height: 2.5rem;
              margin: 1rem 0;
              margin-left: 0;
              box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
              i{
                color: gray;
                font-size: 34px;
                transition: 0.3s ease-in-out;
              }
              &:hover{
                i{
                  transition: 0.3s ease-in-out;
                  color: #000;

                }
              }
            }
          }
        }
      }
      .accordion{
        .accordion-item{
          border: none;
          
          .accordion-button{
            border-bottom: 1px solid #000;
            border-radius: 0;
            background-color: transparent;
            color: #000;
            box-shadow: none;
            padding-left: 0;
            padding-bottom: .5rem;
            margin-left: var(--bs-accordion-btn-padding-x);
            font-size: 19px;
            &:focus{
              // border: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
.product-customization{
  .card{
    border: none;
    p{
      font-size: 21px;
    }
    label{
      font-size: 19px;
      text-transform: capitalize;
      small{
        font-size: 16px;
      }
    }
    .card-title{
      font-size: 29px;
    }
    .form-group{
      margin-bottom: 1rem;
      .product-message{
        &:focus{
          box-shadow: none;
          border-color: #000;
        }
        &:focus-visible{
          outline: none;
        }
        &.form-control{
          font-size: 1.5rem;
          min-height: 30px;
        }
      }
      .custom-file{
        position: relative;
        z-index: 11;
        cursor: pointer;
        &:hover:active{
          &:before{
            
            background-color: #707070;
            border-color: #707070;
          }
        }
        &:hover{
          &:before{
            transition: 0.3s ease;
            background-color: #000;
            color: #fff;
          }
        }
        &:before{
          transition: 0.3s ease-in-out;
          position: absolute;
          width: 100px;
          height: 40px;
          border: 1px solid black;
          border-radius: 0;
          content: "Wybierz Plik";
          text-align: center;
          padding-top: 0.3rem;
          font-size: 19px;
        }
        &:after{
          position: absolute;
          left: 110px;
          font-size: 19px;
          top: 6px;
        }
        &:has(.selected){
          &:after{
            content: 'Wybrano Plik';
          }
        }
        &:not(:has(.selected)){
          &:after{
            content: 'Brak Pliku'
          }
        }
        .custom-file{
          &-input{
            opacity: 0; 
            height: 40px;
            width: 190px;
            // width: 100px;          
            // cursor: pointer;
          }
          &-label{
            display: none;
            &.selected{
              display: block;
            }
            padding-top: 6px;
          }
        }
      }
    }
    .btn-primary{
      @extend .btn-main-reversed;
      background-color: #fff;
      color: #000;
      font-size: 19px;
      padding-top: 0.4rem;
    }
  }
}

#product{
  .product-tabs{
    border: none;
    margin: 3rem 0;
    .nav-tabs{
      justify-content: unset;
      margin-left: 2rem;
      font-size: 24px;
      border-bottom: none;
      .nav-item{
        margin-left: .5rem;
        .nav-link{
          transition: 0.5s ease-in-out;
          border: none;
          border-bottom: 3px solid transparent;
          &.active{
            transition: 0.5s ease-in-out;
            border-bottom: 3px solid #000;
          }
        }
      }
      
    }
    .tab-content{
      padding-top: 2rem;
      
      .tab-pane{

      }
      .product-description{
        p span, p{
          font-family: var(--bs-body-font-family) !important;
          font-size: 21px !important;

        }
      }
      #product-details{
        width: 500px;
        text-align: center;
        margin: auto;
        .product-manufacturer{
          margin-bottom: 1rem;
        }
        .product-features{
          p{
            font-size: 29px;
          }
          .data-sheet{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            dt, dd{
              flex: 0 0 50%;
              width: 50%;
              font-size: 21px;
            }
            dt:nth-of-type(odd){
              background-color: #b7b7b7ba;
            }
            dd:nth-of-type(odd){
              background-color: #b7b7b7ba;
            }
            dt{
              text-align: right;
              padding-right: 2rem;
            }
            dd{
              padding-left: 2rem;
              text-align: left;
              margin-bottom: 0;
            }
          }

        }
        .product-reference{
          margin-top: 1rem;
        }
        .product-reference, .product-quantities, .product-out-of-stock{
          font-size: 21px;
        }
      }
    }
  }
}

#product{
  .product-flags{
    left: 26%;
  }
}

@media (max-width:1759px){
  #product{
    .product-flags{
      left: 25.5%;
    }
    #content-wrapper{
      padding: 0;
    }
  }
}
@media (max-width:1399px){
  #product{
    .product-flags{
      left: 32.3%;
    }
    #wrapper{
      max-width: 1300px;
    }
  }
}

@media (max-width:1299px){
  #product{
    .product-flags{
      left: 16.3%;
    }
    #wrapper{
      max-width: 1140px;
    }
    .images-container{
      flex-direction: column-reverse;
      .product-thumbs{
        height: 100%;
        width: 100%;
        // display: none;
      }
      .position-relative{
        flex: none;
        max-width: 90%;
        // height: 100%;
      }
    }
  }
  #product{
    .row{
      margin: 0;
    }
    #content-wrapper{
      .left-wrapper, .right-wrapper{
        padding: 0 2rem;
      }
      .right-wrapper .accordion .accordion-item .accordion-button{
        padding-right: 0;
        margin-left: 0;
      }
    }
    .product-quantity{
      margin-left: 1rem;
      margin-right: 1rem;
    }
    
  }
}

@media (max-width:991px){
  #product{
    .row{
      margin: 0;
    }
    #content-wrapper{
      .left-wrapper, .right-wrapper{
        padding: 0 2rem;
      }
      .right-wrapper .accordion .accordion-item .accordion-button{
        padding-right: 0;
        margin-left: 0;
      }
      .right-wrapper{
        align-items: center;
        flex-direction: column;
        display: flex;
        gap: 15px;
        & > .category{
          display: none;
        }
      }
    }
    .product-quantity{
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .position-relative{
      height: 100%;
    }
    
  }
}