#category{
  .l-wrapper{
    position: relative;
  }
  .row, .products{
    margin-right: 0;
  }
  #left-column{
    padding-left: 0;
    .block-categories{
      border: none;
    }
    .search_filters{
      border: none;
      & > p {
        display: none;
      }
      .facet__block{
        border: none;
        .facet__title{
          font-size: 29px;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-weight: 500;
        }
        .custom-control-input{
          
        }
        .color__label{
          font-size: 29px;
          letter-spacing: 1px;
          line-height: 46px;
          text-transform: uppercase;
          font-weight: 400;
        }
      }
    }
  }
  #content-wrapper{
    padding-right: 0;
    // padding-left: calc(var(--bs-gutter-x) * 4);
    .product-miniature{
      padding-right: 0;
      padding-left: calc(var(--bs-gutter-x) * 3);
      &:nth-child(3n){
        padding-right: 0;
      }
    }
  }
  #products{
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
  }
  #js-product-list-top{
    // margin-right: 0;
    position: absolute;
    top: -128px;
    right: 15px;
    .total-products, & > .visible--mobile{
      display: none;
    }
    .sort-container{
      padding: 0;
      .form-group{
        display: flex;
        align-items: center;
      }
      label{
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 400;
        margin-right: .5rem;
        min-width: 100px;
        text-align: right;
      }
      select{
        color: #838383;
        font-size: 13px;
        letter-spacing: 1px;
        // line-height: 46px;
        border-color: #000;
        background: #fff;
        padding: 0.4rem 0.4rem;
      }
    }
  }
}


.block-category{
  position: relative;
  border: none;
  padding: 0;
  padding-bottom: 5rem;

  #_desktop_category_header{
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1{
      color: #fff;
      font-size: 92px;
      letter-spacing: 5px;
      font-weight: 500;
    }
  }
  #category-description{
    display: none;
  }
  .category-cover{
    width: 100%;
    height: 100%;
    img{
      width: 100%;
    
    }
  }
}

@media (max-width: 1199px){
  #category {
    .left-column{
      padding-right: 0;
    }
    #products{
      .product-miniature{
        flex: 0 0 50%;
        width: 50%;
      }
    }
  }
}

@media (max-width: 991px){
  #category{
    .l-wrapper{
      max-width: 910px;
    }
  }
}