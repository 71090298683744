@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Teko:wght@300;400;500;600;700&display=swap');

//
// Color system
//
$white:         #fff;
$primary:       #2fb5d2;
$black:         #000;
$gray-200:      #e9ecef;
$gray-300 : #f1f1f1;
$gray-400: #ced4da;
$theme-primary-color: #000;
$theme-secondary-color: rgba(33, 37, 41, 0.75);
$theme-third-color: rgb(13, 110, 253);

// Options
$enable-rounded:            false;
$enable-shadows:            true;

//
// Links
//
// Style anchor elements.

$form-select-indicator-color:  black;
$form-select-indicator:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
$link-hover-decoration:     none;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1680px
) !default;

//
// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1600px
) !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

:root {
  --bs-body-font-family: "Teko", Helvetica, Arial, sans-serif;
} 

$font-family-sans-serif:      'Teko', sans-serif !default;
$headings-font-family:        'Teko', sans-serif;
$btn-font-family:             'Teko', sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`


$h1-font-size:                $font-size-base * 1.375;
$h2-font-size:                $font-size-base * 1.25;
$h3-font-size:                $font-size-base * 1.125;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.9375;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;

$small-font-size:             $font-size-base * 0.875;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;

