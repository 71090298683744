.block-categories {
  @extend .card;
}

.block-categories{
  @extend .card-body;
}

#category .block-categories {
  .mobile{
    display: none;
  }
  .desktop{
    display: block;
  }
  box-shadow:$card-box-shadow;
  .material-icons{
    &.add, .remove{
      @extend .black-to-white-bg-transition;
    }
  }
  & > .category-top-menu{
    & > :first-child{
      margin-bottom: .5rem;
      a{
        font-size: 29px;
        @extend .default-color-transition;
      }
    }
  }
  a{
    color:$body-color;
  }
  .category-sub__item{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .category-sub-item-title{
      display: flex;
      position: relative;
      width: 100%;
      align-items: center;
      & > a {
        line-height: 23px;
        font-size: 23px;
        @extend .default-color-transition;
      }
      & > div, & > i{
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }
    .collapse{
      flex: 0 0 100%;
      width: 100%;
    }
  }
  .category-sub__item--0{
    .category-sub-item-title > a{
      font-size: 25px;
    }
    padding: calc($spacer / 4) calc($spacer / 4);
    padding-right: 0;
  }
  .category-sub__item--1{
    .category-sub-item-title > a{
      font-size: 23px;
    }
    padding: calc($spacer / 4) calc($spacer / 2);
    padding-right: 0;
  }
  .category-sub__item:not(.category-sub__item--0){

  }
  .category-sub__item--2{
    .category-sub-item-title > a{
      font-size: 21px;
    }
    padding: calc($spacer / 4) calc($spacer / 1);
    padding-right: 0;
  }
  .category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
    padding-left: calc($spacer / 4);
  }
  .collapse-icons .remove {
    display: none;
  }
  .collapse-icons[aria-expanded=true] {
    &.remove{
    display: block;
    }
    &.add{
      display: none;
    }
  }
}

@media (max-width:991px){

  #category{
    .row{
      margin-right: calc(-0.5 * var(--bs-gutter-x));
    }
    .block-category{
      padding-bottom: 2rem;
    }
    .block-categories{
      padding: 0;
      margin: 0;
      flex: 0 0 60%;
      width: 60%;
      .desktop{
        display: none;
      }
      .mobile{
        display: block;
      }
    }
    #content-wrapper{
      padding-right: calc(var(--bs-gutter-x) * 2);
      padding-left: calc(var(--bs-gutter-x) * 2);
      .product-miniature{
        padding-right: calc(var(--bs-gutter-x) * 1);
        padding-left: calc(var(--bs-gutter-x) * 1);
      }
      .product-miniature:nth-child(3n){
        padding-right: calc(var(--bs-gutter-x) * 1);
      }
    }

  }
}

@media (max-width:767px){
  #category{
    #content-wrapper{
      margin-top: 4rem;
    }
    #js-product-list-top{
      right: 50%;
      transform: translateX(50%);
    }
  }
}

@media (max-width:575px){
  #category{
    #js-product-list{
      .products{
        .product-miniature{
          flex: 0 0 100%;
          width: 100%;
        }
      }
    }
  }
}