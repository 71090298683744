.right-wrapper{
  .product-information{
    .product-additional-info{
      .product-comments-additional-info{
        display: none;
      }
    }
  }
}
#product-comments-panel{
  #product-comments-list-header{
    flex-direction: row-reverse;
    align-items: center;
    .comments-note{
      display: flex;
      align-items: center;
      > span{

      }
    }
  }
  #empty-product-comment{
    font-size: 24px;
    button{
      background-color: #fff;
      color: #000;
      font-size: 19px;
      padding-top: 0.6rem;
      box-shadow: none;
      border-color: #000;
      transition: 0.3s ease-in-out;
      &:hover{
        transition: 0.3s ease-in-out;
        background-color: #000;
        color: #fff;
      }
    }
  }
}

.product-quickview-review{
  justify-content: center;
  .grade-stars{
    margin-top: 0.1rem;
    margin-right: 0.3rem;
  }
  .comments-nb{
    font-size: 22px;
  }
}

#product-comments-list-header,
 .product-comment-list-item,
  #post-product-comment-form,
  .product-quickview-review{
  .comments-nb{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    font-size: 24px;
    i{
      
    }
  }
  .comments-note{
    & > span{
      font-size: 21px;
    }
  }
  .grade-stars{
    height: 32px;
    min-width: (5 * 27px) + (5 * 3px);
    .star-content{
      background: none;
      top: 0;
      .star, .star-on{     
        background: none;
        width: 27px;
        height: 27px;
        svg{
          width: 27px;
          height: 25px;
        }
      }
      &.star-empty{
        .star{
          color: rgb(145 164 170);

        }
      }
      &.star-full{
        .star-on, .star-hover{
          color: rgb(243 157 114);
          overflow: hidden;
          // stroke: rgb(145 164 170);
          //   stroke-width: 1px;
        }
        .star:not(.star-on, .star-hover){
          color: rgb(145 164 170);
        }
      }
    }
  }
}
#post-product-comment-form{
  .star-full{
    .star-on, .star-hover{
      position: relative;
     &:before{
      position: absolute;
      font-family: 'Bootstrap-icons';
      content: '\F586';
      top: -5px;
      left: 2px;
      font-size: 23.5px;
     }
    }
  }
}
#product-comments-list-header{
  
}

#product-comments-list{
  .product-comment-list-item{
    box-shadow: 0px 0px 3px 0px #6c6c6c73;
    margin-top: 2rem;
    .comment-infos{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .comment-date{
        order: 2;
      }
      .comment-author{
        margin-bottom: .5rem;
      }
      .comment-date, .comment-author{
        font-size: 19px;
        color: #000;
      }
    }
    .comment-content{
      display: flex;
      flex-direction: column;
      transition: 1s ease-in-out; 
      p.h4{
        font-size: 24px;
        text-transform: capitalize;
        height: auto;
        overflow: unset;
        
        
      }
      .comment-body{
        height: 75px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
        p{
          font-size: 21px;
          transition: 0.3s ease-in-out;
          padding-right: 0.5rem;
        }
        &.collapseComment{
          transition: 0.4s ease-in-out;
          height: 100%;
        }
      }
      .comment-show-more{
        transition: 0.3s ease-in-out;
        text-align: right;
        width: 100%;
        display: none;
        padding: 0.5rem 0 1rem 0;
        font-size: 19px;
        color: $theme-secondary-color;
        cursor: pointer;
        position: relative;
        padding-right: 1.7rem;
        &:after{
          font-family: 'Bootstrap-icons';
          display: block;
          position: absolute;
          content: '\F282';
          right: 0;
          top: 7px;
        }
        &:hover{
          transition: 0.3s ease-in-out;
          color: $theme-third-color;
        }
        &.show{
          display: block;
        }
      }
      .comment-buttons{
        width: 100%;
        justify-content: flex-end;
        a .material-icons, a span{
          transition: 0.2s ease-in-out;
          font-size: 27px;
        }
        a span{
          padding-top: .35rem;
          margin-left: 0.3rem;
        }
        a:hover{
          .material-icons{
            transition: 0.3s ease-in-out;
            color: $theme-third-color;
          }
        }
      }
    }
  }
}

#product-comment-posted-modal{
  .modal-header{
    p{
      font-size: 27px;
    }
    i{
      display: none;
    }
  }
  .modal-body{
    #product-comment-posted-modal-message{
      font-size: 21px;
    }
    .post-comment-buttons{
      .btn-comment{
        padding-top: 0.9rem;
        font-size: 18px;
        width: 100px;
        box-shadow: unset;
        border-color: #000;
        background-color: #fff;
        color: #000;
        transition: 0.3s ease-in-out;
        &:hover{
          transition: 0.3s ease-in-out;
          background-color: #000;
          color: #fff;

        }
      }
    }
  }
}

#product-comments-list-footer{
  #product-comments-list-pagination{
    li span{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li{
      span:not(.next, .prev){
        padding-top: 0.3rem;
      }
    } 
    @media (min-width:960px){
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .btn-comment{
    transition: 0.2s ease-in-out;
    background-color: #fff;
    color: #000;
    box-shadow: none;
    border-color: #000;
    border-radius: 0;
    font-size: 21px;
    display: flex;
    align-items: center;
    height: auto;
    &:hover{
      transition: 0.2s ease-in-out;
      background-color: #000;
      color: #fff;
    }
    i{
      font-size: 25px;
    }
  }
}

#post-product-comment-modal{
  .modal-content{
    .modal-header{
      p{
        font-size: 29px;
        margin-bottom: 0;
      }
      button{
        &:hover{
          span{
            font-size: 26px;
            transition: 0.3s ease;
            transform: rotate(180deg);
          }
        }
      }
    }
    .modal-body{
      .product-description{
        display: flex;
        .product-image{
          .product-flags{
            display: none;
          }
          .product-cover{
            width: 100%;
            height: 100%;
            img{
              height: 100%;
            }
          }
        }
        .product-name{
          p{
            font-size: 21px;
            text-align: center;
            margin-top: 50%;
            transform: translateY(-50%);
          }
        }
        .product-rating{
          display: flex;
          justify-content: center;
          align-items: center;
          .criterion-rating{
            align-items: center;
            label{
              font-size: 21px;
            }
          }
        }
      }
      .form-label{
        font-size: 19px;
        sup{
          color: red;
          &:after{
            content: '';
          }
        }
      }
      p.required{
        font-size: 19px;
        sup{
          color: red;
        }
        &:after{
          content: '';
        }
      }
      .post-comment-buttons{
        padding-right: 0;
        button{
          width: 120px;
          transition: 0.3s ease-in-out;
          box-shadow: none;
          padding-top: 0.9rem;
          &:hover{
            transition: 0.3s ease-in-out;
            background-color: #000;
            color: #fff;
          }
          &.btn-comment{
            background-color: #000;
            &:hover{
              background-color: #424242;
            }
          }
        }
      }
    }
  }
}

.modal-content .modal-header .material-icons {
  color: #000;
  font-size: 1.5rem;
  line-height: 1;
}

@media (max-width:991px){
  #product-comments-list .product-comment-list-item{
    .comment-infos {
      .comment-author{
        text-align: center;
      }
      .comment-date{
        text-align: center;
      }
    }
  }
}
@media (max-width:768px) and (min-width:576px){
  #product-comments-list .product-comment-list-item{
    .comment-infos {
      .comment-author{
        padding-top: 1rem;
      }
    }
  }
}
@media (max-width:576px){
  #product-comments-list .product-comment-list-item{
    .comment-infos {
      .comment-date{
        padding-bottom: 1rem;
      }
    }
  }
  #product .product-tabs .nav-tabs{
    margin-left: 0; 
  }
  #product .product-tabs .tab-content #product-details{
    max-width: 400px;
    width: auto;
  }
}