.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

.copyright-text{
  padding: 20px;
  color: $theme-third-color;
  font-size: 11px;
  .copyright{
    margin-bottom: -2px;
    margin-right: 10px;
  }
  .made-by{
  }
  a{
    font-size: 15px;
    img{
      max-width: 100px!important;
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 3px;
    }
  }
}

#module-blockwishlist-lists{
  footer{
    margin-top: 5rem;
  }
}

#gdpr_consent{
  .custom-control{
    display: flex;
    flex-direction: row;
    &-input{
      flex: 0 0 auto;
      width: 1.25em;
      height: 1.25em;
      &:before{
        width: 0.7em;
        height: 0.7em;
        margin-top: 0.05em;
      }
    }
    &-label{
      margin-left: 5px;
      font-size: 14px;
    }
  }
}