.btn-main-base{
  transition: 200ms;
  padding: .4rem 0.6rem;
  border-radius: 0;
  padding-top: .6rem;
  &:hover{
    transition: 200ms ease-in;
  }
}

.btn-main{
  @extend  .btn-main-base;
  background-color: $main-btn-color;
  color: $main-btn-text-color;
  
  .quick-view{
    font-size: 22px;
    line-height: 38px;
    font-weight: 400;
    width: 100%;
    // padding: .4rem 1.2rem;
  }

  &:hover{
    
    background-color: $main-btn-color-hover;
    color: $main-btn-text-color;
  }
}

.btn-main-reversed{
  @extend .btn-main-base;
  
  border: 1px solid black;
  &:hover{
    background-color: $main-btn-color;
    color: #fff;
  }
}



.custom-control + .custom-control{
  // margin-top: 1em;
}

// swiper

.featured-products{
  .products-section-header{
    position: relative;
    
  }
}